<template>
  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-center align-items-center">
    <app-timeline>
      <!--    <app-timeline-item-->
      <!--      v-if="location"-->
      <!--      icon="SmartphoneIcon"-->
      <!--    >-->
      <!--      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">-->
      <!--        <h6>{{ $t('common.scan_the_qr_code') }}</h6>-->
      <!--      </div>-->
      <!--      <p>{{ $t('point.the_request_form_will_be_opened') }}</p>-->

      <!--      <location-q-rcode :location="location" />-->

      <!--    </app-timeline-item>-->

      <app-timeline-item
        :title="`${$t('point.enter_the_name_of_the_service_you_offer')}`"
        :subtitle="`${$t('Example:')} ${getPurposeTitle('plumber')}`"
        icon="FileTextIcon"
        time=""
        variant="warning"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />

      <app-timeline-item
        :title="`${$t('point.briefly_describe_the_service_offered')}`"
        :subtitle="`${$t('Example:')} ${$t('Installation and repair of kitchen sinks, replacement of taps, water and sewer pipes')}`"
        icon="FileTextIcon"
        time=""
        variant="warning"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />

      <app-timeline-item
        v-if="!location"
        :title="`${$t('location.specify_location')}`"
        subtitle=""
        icon="MapPinIcon"
        time=""
        variant="warning"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        v-if="!userId"
        :title="`${$t('common.specify_your_name_and_contact_phone_number')}`"
        :subtitle="userId"
        icon="UserIcon"
        time=""
        variant="warning"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        :title="`${$t('common.press_the_button')} '${$t('Add Service Offer')}'`"
        subtitle=""
        icon="CheckCircleIcon"
        time=""
        variant="warning"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        title=""
        subtitle=""
        icon="PhoneCallIcon"
        time=""
        variant="success"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      >
        <p :class="subTitleSize">
          {{ $t('point.now_you_can_see_all_the_requests_for_your_services_and_send_your_offers') }}
        </p>

      </app-timeline-item>
    </app-timeline>
    <b-alert
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        {{ $t('common.advice') }}
      </h4>
      <div class="alert-body">

        <span>{{ $t('point.duplicate_the_service_offer_in_other_languages_you_speak') }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LocationQRcode from '@/views/apps/location/details/LocationQRcode.vue'
import AppTimeline from '@/layouts/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/layouts/components/app-timeline/AppTimelineItem.vue'
import { serviceOptions } from '@/mixins/options'

export default {
  components: {
    // LocationQRcode,
    AppTimelineItem,
    AppTimeline,
    BAlert,
  },
  directives: {
    Ripple,
  },

  props: {
    location: {
      type: Object,
      required: false,
      default: () => {},
    },
    titleSize: {
      type: String,
      default: 'h6',
    },
    subTitleSize: {
      type: String,
      default: 'h6',
    },
    userId: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
    }
  },

  methods: {

  },
  setup() {
    const { getPurposeTitle } = serviceOptions
    return {
      getPurposeTitle,
    }
  },
}

</script>
